import { useState, useEffect, useRef } from "react";
import "./navbar.scss";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { jwtDecode } from "jwt-decode";

export const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [username, setUsername] = useState("");
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const navigate = useNavigate();

  // Handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUsername(decoded.username);
      } catch (err) {
        console.error("Not Logging in");
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUsername("");
    navigate("/");
  };

  return (
    <motion.div className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <motion.div
        className="icon"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
      >
        <img src="/logo.png" alt="" />
        <h1>Aranburi</h1>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
        className="link"
      >
        <Link to={`/`} className="imageContainer">
          <p>Home</p>
        </Link>
        <Link to={`/about`} className="imageContainer">
          <p>About us</p>
        </Link>
        <Link to={`/destination`} className="imageContainer">
          <p>Destination</p>
        </Link>
        <Link to={`/experience`} className="imageContainer">
          <p>Experiences</p>
        </Link>
        <Link to={`/`} className="imageContainer">
          <p>Sustainability</p>
        </Link>
        {username ? (
          <div
            className="imageContainer"
            style={{ display: "flex", alignItems: "center", gap: "40px" }}
          >
            {username}
            <p onClick={handleLogout}>Logout</p>
          </div>
        ) : (
          <Link
            to={`/login`}
            className="imageContainer"
            onClick={() => setOpen(false)}
          >
            Login
          </Link>
        )}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
        className="hamburger"
        onClick={() => setOpen((prev) => !prev)}
        ref={hamburgerRef}
      >
        <FontAwesomeIcon
          icon={faBars}
          size="2xl"
          className={open ? "bar" : ""}
        />
      </motion.div>
      <div className={open ? "menu active" : "menu"} ref={menuRef}>
        <Link
          to={`/`}
          className="imageContainer"
          onClick={() => setOpen(false)}
        >
          <p>Home</p>
        </Link>
        <Link
          to={`/about`}
          className="imageContainer"
          onClick={() => setOpen(false)}
        >
          <p>About us</p>
        </Link>
        <Link
          to={`/destination`}
          className="imageContainer"
          onClick={() => setOpen(false)}
        >
          <p>Destination</p>
        </Link>
        <Link
          to={`/experience`}
          className="imageContainer"
          onClick={() => setOpen(false)}
        >
          <p>Experiences</p>
        </Link>
        <Link
          to={`/`}
          className="imageContainer"
          onClick={() => setOpen(false)}
        >
          <p>Sustainability</p>
        </Link>

        {username ? (
          <div
            className="imageContainer"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "40px",
              flexWrap: "wrap",
            }}
          >
            {username}
            <p onClick={handleLogout} style={{ cursor: "pointer" }}>
              Logout
            </p>
          </div>
        ) : (
          <Link
            to={`/login`}
            className="imageContainer"
            onClick={() => setOpen(false)}
          >
            Login
          </Link>
        )}
      </div>
    </motion.div>
  );
};
