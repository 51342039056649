import "./why.scss";
import { motion } from "framer-motion";

export const Why = () => {
  return (
    <section className="why" style={{ backgroundImage: `url(/green.jpg)` }}>
      <div className="header">
        <h1>Why Aranburi ?</h1>
        <div className="line"></div>
      </div>
      <motion.div className="card-wrapper">
        <motion.div
          className="card"
          style={{ backgroundImage: `url(/why1.jpg)` }}
        >
          <div className="text">
            <h1>Commitment to Sustainability</h1>
            <p>
              We prioritize the environment in every aspect of our operations.
              Our destinations and practices are carefully selected to align
              with Thailand's 17 Sustainable Tourism Goals (STGs). This ensures
              that your travel experience contributes positively to the
              preservation of natural landscapes, wildlife, and local
              communities.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="card"
          style={{ backgroundImage: `url(/why2.jpg)` }}
        >
          <div className="text">
            <h1>Eco-Luxury Accommodations</h1>
            <p>
              Our accommodations combine the highest standards of luxury with
              eco-friendly practices. From energy-efficient buildings to locally
              sourced materials, every detail is designed to minimize
              environmental impact while providing exceptional comfort and
              elegance.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="card"
          style={{ backgroundImage: `url(/why3.jpg)` }}
        >
          <div className="text">
            <h1>Curated Experiences</h1>
            <p>
              Whether you choose a tailor-made trip, a pre-made package, or a
              one-day escape, our travel experiences are meticulously curated to
              immerse you in the beauty of nature. Each journey is crafted to
              provide unforgettable memories while respecting and preserving the
              environment.
            </p>
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};
