import "./intro.scss";

export const Intro = ({ topic, img, desc, left, bottom }) => {
  return (
    <section className="introSection">
      <div
        className="us"
        style={{
          backgroundImage: `url(${img})`,
          "--left": left,
          "--bottom": bottom,
        }}
      >
        <div className="content">
          <h1>{topic}</h1>
          <p>{desc}</p>
        </div>
      </div>
    </section>
  );
};
