import { useEffect } from "react";
import { Intro } from "../../components/intro/Intro";
import "./aboutPage.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { Why } from "../../components/why/Why";
import { Footer } from "../../components/footer/Footer";

export const AboutPage = () => {
  useEffect(() => {
    const topElement = document.documentElement;
    if (topElement) {
      topElement.scrollIntoView({ behavior: "instant", block: "start" });
    }
  }, []);
  return (
    <div className="about">
      <Intro
        topic="About us"
        img="aboutus.jpg"
        desc="Learn More About Aranburi"
        bottom="40%"
        left="50%"
      />
      <section className="welcomeSection">
        <div className="story">
          <h1>Aranburi's story</h1>
          <div className="line"></div>
          <p>
            Aranburi was born from the vision of a group of university students
            passionate about transforming travel in Thailand. With a deep
            appreciation for Thailand's rich culture and pristine landscapes, we
            set out to create a travel agency that redefines luxury travel
            through sustainability. Our goal was simple yet ambitious: to
            provide opulent travel experiences that also preserve and protect
            the environment. From our humble beginnings, we have grown into a
            trusted name in eco-luxury travel, creating unforgettable
            experiences that honor both our clients and the planet.
          </p>
        </div>
        <div className="box">
          <div className="image">
            <img src="/story.jpg" alt="" />
          </div>

          <div className="text-box">
            <p>
              Aranburi is a green-luxury travel agency offering tour packages,
              Taylor-made holidays, one-day activities, and more to all
              sustainable destinations in Thailand. Traveling is one of the
              biggest sources of carbon emissions on Earth. However, everybody
              still needs some holidays. Aranburi supports goals that include
              protecting the environment, addressing climate change, minimizing
              plastic consumption, and expanding economic development. while
              providing you with the best travel experiences by using STARs
              standards to indicate greenness. We are not just another travel
              agency. We are Thais providing holiday packages in Thailand with
              the will, to make travel more sustainable.
            </p>
          </div>
        </div>
      </section>

      <section className="service">
        <div className="top">
          <h1>Our Services</h1>
          <div className="line"></div>
        </div>
        <div className="slider">
          <div className="card">
            <img src="/train2.jpg" alt="" />
            <div className="text-wrapper">
              <h1>Unique Travel Experiences</h1>
              <p>
                Aranburi offers a variety of bespoke travel experiences tailored
                to your preferences. Whether you're seeking secluded beach
                retreats, adventurous jungle expeditions, or serene cultural
                immersions, we craft itineraries that are as unique as you are.
              </p>
            </div>
          </div>
          <div className="card">
            <img src="/tuk.jpg" alt="" />
            <div className="text-wrapper">
              <h1>Personalized Service</h1>
              <p>
                We understand that every traveler is different. Our team works
                closely with you to design a journey that meets your specific
                desires and needs, ensuring a seamless and memorable experience.
              </p>
            </div>
          </div>
          <div className="card">
            <img src="/boat.jpg" alt="" />
            <div className="text-wrapper">
              <h1>Local Partnerships</h1>
              <p>
                We collaborate with local businesses, artisans, and guides to
                provide authentic and sustainable travel experiences. Our
                partnerships ensure that your trip supports local economies and
                offers genuine insights into Thai culture.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="greenSection">
        <img src="green.jpg" alt="" />
      </div>

      <section className="team-wrapper">
        <div className="top">
          <h1>Meet Aranburi Team</h1>
          <div className="line"></div>
        </div>
        <div className="slider">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={2}
            loop={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="swiper"
            style={{
              "--swiper-pagination-color": "#0D281E",
              "--swiper-pagination-bullet-inactive-color": "#999999",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": "7px",
              "--swiper-pagination-bullet-horizontal-gap": "6px",
            }}
          >
            <SwiperSlide className="card">
              <img src="/wan3.jpg" alt="wan" />
              <div className="text-box">
                <h1>Kanyarat Monklung</h1>
                <p>UX/UI</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="card">
              <img src="/nine.jpg" alt="nine" />
              <div className="text-box">
                <h1>Napatr Sawamipak</h1>
                <p>Developer</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="card">
              <img src="seree.jpg" alt="hi" />
              <div className="text-box">
                <h1>Seree Sae-iang </h1>
                <p>CEO & Strategy</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="card">
              <img src="aiim.jpg" alt="hi" />
              <div className="text-box">
                <h1>Chanikarn Wanla</h1>
                <p>UX/UI</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="card">
              <img src="pun.jpg" alt="hi" />
              <div className="text-box">
                <h1>Natchanon Chomchoe</h1>
                <p>COO</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="card">
              <img src="pong.jpg" alt="hi" />
              <div className="text-box">
                <h1>Jewapong Putthasong</h1>
                <p>Finance</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section className="guideSection">
        <div className="top">
          <h1>Local guides and Partners</h1>
          <div className="line"></div>
        </div>
        <p>
          We are proud to work with a network of local guides and partners who
          share our values and dedication to providing exceptional travel
          experiences. Their insights and expertise add depth and authenticity
          to your journey.
        </p>
      </section>

      <Why />
      <Footer />
    </div>
  );
};
