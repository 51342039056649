import "./login.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useState } from "react";

import { Google } from "../../components/google/Google";

export const LoginPage = () => {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(
        "https://dthbvkpyng.us-east-1.awsapprunner.com/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await res.json();

      if (data.success === false) {
        setLoading(false);
        setError(data.message);
        console.log(data.message); // Log the error message
        return;
      }
      localStorage.setItem("token", data.token);
      window.location.href = "/";
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Something went wrong. Please try again.");
      console.log("Something went wrong. Please try again."); // Log generic error message
    }
  };

  return (
    <div className="loginSection">
      <div className="login" style={{ backgroundImage: "url(/green.jpg)" }}>
        <div className="wrapper">
          <div className="top">
            <Link className="box" to={`/login`}>
              <h1>Log in</h1>
            </Link>
            <Link className="box" to={`/signup`}>
              <h1>Sign up</h1>
            </Link>
          </div>
          <Google />
          <div className="or">
            <div className="line"></div>
            <p>OR</p>
            <div className="line"></div>
          </div>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="email"
              id="email"
              onChange={handleChange}
            />
            <div className="input-container">
              <FontAwesomeIcon
                icon={faLock}
                style={{ color: "rgba(0,0,0,0.5)" }}
                className="icon"
              />
              <input
                id="password"
                type="password"
                placeholder="password"
                className="input-with-icon"
                onChange={handleChange}
              />
            </div>
            <button type="submit" disabled={loading}>
              {loading ? "Loading..." : "Login"}
            </button>
          </form>
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
    </div>
  );
};
