import "./homepage.scss";
import "./travel.scss";
import "./stgs.scss";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { Why } from "../../components/why/Why";
import { Footer } from "../../components/footer/Footer";

export const HomePage = () => {
  useEffect(() => {
    const topElement = document.documentElement;
    if (topElement) {
      topElement.scrollIntoView({ behavior: "instant", block: "start" });
    }
  }, []);
  const handleArrowClick = () => {
    document
      .querySelector(".welcomeSection")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="homepage">
      <section className="introSection">
        <div className="arrow" onClick={handleArrowClick}>
          <FontAwesomeIcon icon={faArrowDown} style={{ color: "white" }} />
        </div>
        <div
          className="fullscreen-video-wrap"
          dangerouslySetInnerHTML={{
            __html: `
    <video playsinline loop autoPlay muted autobuffer poster="./media/bg.jpg">
    <source src="sea.mp4" type="video/mp4" />
    Your browser does not support the video tag. I suggest you upgrade your browser.
</video>            
`,
          }}
        ></div>

        <div className="content">
          <motion.div className="top">
            <motion.h1
              className="top"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
            >
              Aranburi
            </motion.h1>
            <motion.p
              className="top"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.3 }}
            >
              Where Luxury Meets Sustainability
            </motion.p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.3 }}
            className="bottom"
          >
            <Link to={`/`} className="imageContainer">
              <p>Booking</p>
            </Link>
            <Link to={`/`} className="imageContainer">
              <p>View all destination</p>
            </Link>
          </motion.div>
        </div>
      </section>
      <section className="welcomeSection">
        <div className="top">
          <motion.div
            className="left"
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.1 }}
          >
            <h1 className="title">Welcome To Aranburi</h1>
            <p className="content">
              At Aranburi, luxury and sustainability blend seamlessly to create
              unforgettable travel experiences. Our curated destinations provide
              the utmost comfort while immersing you in the beauty of nature.
              Each location aligns with Thailand's 17{" "}
              <span>Sustainable Tourism Goals (STGs),</span> ensuring your
              journey is both elegant and eco-friendly.
            </p>
            <div className="aboutus">
              <Link to={`/`} className="imageContainer">
                <p>ABOUT US</p>
              </Link>
            </div>
          </motion.div>
          <motion.div
            className="right"
            initial={{ opacity: 0, x: 200 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5 }}
          >
            <img src="jo.jpg" alt="" />
          </motion.div>
        </div>
        <div className="bottom">
          <h1>Join us at Aranburi and discover a new way to travel—</h1>
          <p>
            where every journey is a testament to the beauty of our planet and
            the elegance of eco-luxury.
          </p>
        </div>
      </section>

      <div className="greenSection">
        <img src="green.jpg" alt="" />
      </div>

      <section className="travelSection">
        <div className="top">
          <h1>Our Travel experiences</h1>
          <div className="line"></div>
          <p>
            Explore our three main offerings to find the ideal travel experience
            that meets your desires and respects the environment.
          </p>
        </div>
        <motion.div className="slider-wrapper">
          <div className="slider justify-evenly-until-scroll ">
            <div className="card">
              <div className="imgContainer">
                <img src="card1.jpg" alt="" />
              </div>
              <div className="text">
                <h1>Dream journey</h1>
                <p>
                  Create your dream vacation with our personalized travel
                  planning service. Every detail is tailored to your
                  preferences, ensuring a unique and unforgettable journey.
                </p>
              </div>
              {/* <button>PLAN YOUR TRIP</button> */}
              <Link to={`/dream-journey`} className="button">
                PLAN YOUR TRIP
              </Link>
            </div>
            <div className="card">
              <div className="imgContainer">
                <img src="train.jpeg" alt="" />
              </div>
              <div className="text">
                <h1>Perfect Pairing</h1>
                <p>
                  Choose from our expertly crafted travel packages. Designed for
                  seamless luxury and eco-friendly exploration, our pre-made
                  trips offer the perfect getaway.
                </p>
              </div>
              <Link to={`/perfect-pairing`} className="button">
                EXPLORE PACKAGES
              </Link>
            </div>
            <div className="card">
              <div className="imgContainer">
                <img src="card3.jpg" alt="" />
              </div>
              <div className="text">
                <h1>exclusive activity</h1>
                <p>
                  Experience the beauty and tranquility of nature in just one
                  day. Our curated one-day trips provide a quick escape to
                  rejuvenate and reconnect with the environment.
                </p>
              </div>
              <Link to={`/exclusive-activity`} className="button">
                BOOK A DAY TRIP
              </Link>
            </div>
          </div>
        </motion.div>
      </section>

      <Why />

      <section
        className="stgsSection"
        style={{ backgroundImage: `url(/bg3.jpg)` }}
      >
        <motion.div className="box">
          <div className="left">
            <img src="/brand-logo.webp" alt="" />
          </div>
          <div className="right">
            <h1>What is STGs?</h1>
            <p>
              Thailand's Sustainable Tourism Goals (STGs) are a set of 17
              objectives designed to promote sustainability in the tourism
              industry. The STAR recognizes companies that fulfill these goals,
              which focus on preserving the environment, supporting local
              communities, and promoting responsible tourism practices. At
              Aranburi, we are proud to feature destinations that meet these
              high standards, contributing to a more sustainable future for
              travel.
            </p>
            <div className="button">
              <button>EXPLORE MORE</button>
            </div>
          </div>
        </motion.div>
      </section>

      <Footer />
    </div>
  );
};
