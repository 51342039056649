import "./expe.scss";
import { Intro } from "../../components/intro/Intro";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Why } from "../../components/why/Why";
import { Footer } from "../../components/footer/Footer";
import { useEffect } from "react";

export const ExperiencePage = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  useEffect(() => {
    const topElement = document.documentElement;
    if (topElement) {
      topElement.scrollIntoView({ behavior: "instant", block: "start" });
    }
  }, []);
  return (
    <div className="experience">
      <Intro
        topic="EXPERIENCES"
        img="experience.jpg"
        desc="Unforgettable Journeys, Responsibly Curated."
        bottom="600%"
        left="50%"
      />

      <section className="intro">
        <div className="left">
          <div className="text">
            <h1>Discover</h1>
            <h1> Your Perfect Journey</h1>
          </div>

          <p>
            Immerse yourself in the exquisite blend of luxury and sustainability
            with Aranburi. Explore our three exceptional offerings—Tailor-Made
            Trips, Pre-Made Packages, and One-Day Excursions—and discover the
            perfect travel experience tailored to your desires. Each journey is
            meticulously crafted to provide unparalleled comfort and elegance
            while honoring and preserving the natural beauty and cultural
            richness of our destinations. Experience the extraordinary with
            Aranburi, where every moment is a testament to responsible luxury.
          </p>
        </div>
        <img src="evan.jpg" alt="" />
      </section>
      <div className="longline"></div>

      <section className="planSection">
        <div className="topic">
          <h1>Select Your Plan</h1>
          <div className="line"></div>
        </div>
        <div className="plan-wrapper">
          <div className="plan">
            <img src="card1.jpg" alt="" />
            <div className="text">
              <h1>Dream journey</h1>
              <p>
                Create your dream vacation with our personalized travel planning
                service. Every detail is tailored to your preferences, ensuring
                a unique and unforgettable journey.
              </p>
              <div className="button-wrapper">
                <Link to={`/dream-journey`}>
                  <div
                    className={`button ${
                      hoveredIndex === 0 ? "fa-bounce" : ""
                    }`}
                    onMouseEnter={() => setHoveredIndex(0)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <p>PLAN YOUR TRIP</p>
                    <FontAwesomeIcon icon={faArrowRight} className="arrow" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="plan">
            <img src="train.jpeg" alt="" />
            <div className="text">
              <h1>Perfect Pairing</h1>
              <p>
                Choose from our expertly crafted travel packages. Designed for
                seamless luxury and eco-friendly exploration, our pre-made trips
                offer the perfect getaway.
              </p>
              <div className="button-wrapper">
                <Link to={`/perfect-pairing`}>
                  <div
                    className={`button ${
                      hoveredIndex === 1 ? "fa-bounce" : ""
                    }`}
                    onMouseEnter={() => setHoveredIndex(1)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <p>PLAN YOUR TRIP</p>
                    <FontAwesomeIcon icon={faArrowRight} className="arrow" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="plan">
            <img src="card3.jpg" alt="" />
            <div className="text">
              <h1>exclusive activity</h1>
              <p>
                Experience the beauty and tranquility of nature in just one day.
                Our curated one-day trips provide a quick escape to rejuvenate
                and reconnect with the environment.
              </p>
              <div className="button-wrapper">
                <Link to={`/`}>
                  <div
                    className={`button ${
                      hoveredIndex === 2 ? "fa-bounce" : ""
                    }`}
                    onMouseEnter={() => setHoveredIndex(2)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <p>PLAN YOUR TRIP</p>
                    <FontAwesomeIcon icon={faArrowRight} className="arrow" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Why />
      <Footer />
    </div>
  );
};
