import "./app.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Layout } from "./routes/layout/Layout";
import { HomePage } from "./routes/homePage/HomePage";
import { AboutPage } from "./routes/aboutPage/AboutPage";
import { ExperiencePage } from "./routes/experiencePage/ExperiencePage";
import { LoginPage } from "./routes/loginPage/LoginPage";
import { SignupPage } from "./routes/singupPage/SignupPage";
import { TailorPage } from "./routes/tailorPage/TailorPage";
import { PerfectPairingPage } from "./routes/perfectPairingPage/PerfectPairingPage";
import { ActivityPage } from "./routes/activityPage/ActivityPage";
import { DestinationPage } from "./routes/destinationPage/DestinationPage";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/about",
          element: <AboutPage />,
        },
        {
          path: "/experience",
          element: <ExperiencePage />,
        },
        {
          path: "/login",
          element: <LoginPage />,
        },
        {
          path: "/signup",
          element: <SignupPage />,
        },
        {
          path: "/dream-journey",
          element: <TailorPage />,
        },
        {
          path: "/perfect-pairing",
          element: <PerfectPairingPage />,
        },
        {
          path: "/exclusive-activity",
          element: <ActivityPage />,
        },
        {
          path: "/destination",
          element: <DestinationPage />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
