import { useEffect } from "react";
import { Header } from "../../components/header/Header";
import "./tailor.scss";

export const TailorPage = () => {
  useEffect(() => {
    const topElement = document.documentElement;
    if (topElement) {
      topElement.scrollIntoView({ behavior: "instant", block: "start" });
    }
  }, []);
  return (
    <div className="tailor">
      <Header
        img="/card1.jpg"
        topic="Dream journey"
        desc="Share your details below, and we'll reach out to discuss your travel plans."
        left="50%"
        bottom="50%"
      />

      <section className="content-wrapper">
        <div className="top">
          <h1>Allow Us to Create Your Ideal Journey</h1>
          <div className="line"></div>
        </div>
        <form>
          <div className="firstForm">
            <h1>Your Trip</h1>
            <div className="form-container">
              <div className="form-group">
                <label>Where would you like to go? (required*)</label>
                <select>
                  <option>Select a destination</option>
                </select>
              </div>

              <div className="form-group">
                <label>
                  How much are you looking to spend in THB? (required*)
                </label>
                <select>
                  <option>Select your budget</option>
                </select>
              </div>

              <div className="form-group">
                <label>When would you like to go? (required*)</label>
                <div className="date-selectors">
                  <select>
                    <option>Year</option>
                  </select>
                  <select>
                    <option>Month</option>
                  </select>
                  <select>
                    <option>Day</option>
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label>How long will you stay? (required*)</label>
                <select>
                  <option>Day</option>
                </select>
              </div>

              <div className="form-group">
                <label>Would you like a guide to assist you? (required*)</label>
                <div className="radio-buttons">
                  <label>
                    <input type="radio" name="guide" value="yes" /> Yes
                  </label>
                  <label>
                    <input type="radio" name="guide" value="no" /> No
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label>Share more details about your holiday</label>
                <textarea placeholder="e.g., special occasion, desired destinations, must-see attractions"></textarea>
              </div>
            </div>
          </div>
          <div className="firstForm">
            <h1>Your Trip</h1>
            <div className="form-container">
              <div className="form-group">
                <label>Title (required*)</label>
                <select className="select-with-placeholder">
                  <option value="" disabled selected>
                    Select title
                  </option>
                  <option value="mr">Mr</option>
                  <option value="mrs">Mrs</option>
                  <option value="ms">Ms</option>
                </select>
              </div>

              <div className="form-group">
                <label>First Name (required*)</label>
                <input type="text" placeholder="First Name" />
              </div>

              <div className="form-group">
                <label>Middle Name</label>
                <input type="text" placeholder="Middle Name" />
              </div>

              <div className="form-group">
                <label>Last Name (required*)</label>
                <input type="text" placeholder="Last Name" />
              </div>

              <div className="form-group">
                <label>Email Address (required*)</label>
                <input type="email" placeholder="Email Address" />
              </div>

              <div className="form-group">
                <label>Telephone Number (required*)</label>
                <input type="tel" placeholder="+66" />
              </div>

              <div className="form-group">
                <label>Country (required*)</label>
                <select className="select-with-placeholder">
                  <option value="" disabled selected>
                    Country
                  </option>
                  <option value="thailand">Thailand</option>
                  <option value="usa">USA</option>
                  <option value="uk">UK</option>
                </select>
              </div>

              <div className="form-group">
                <label>How did you hear about us? (required*)</label>
                <select className="select-with-placeholder">
                  <option value="" disabled selected>
                    How did you hear about us
                  </option>
                  <option value="internet">Internet</option>
                  <option value="friends">Friends</option>
                  <option value="advertisement">Advertisement</option>
                </select>
              </div>
            </div>
          </div>
          <div className="button">
            <button>SUBMIT</button>
          </div>
        </form>
      </section>
    </div>
  );
};
