import { Intro } from "../../components/intro/Intro";
import "./destination.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";

// import required modules
import { FreeMode, Pagination } from "swiper/modules";
import { useEffect, useState } from "react";
import { Why } from "../../components/why/Why";
import { Footer } from "../../components/footer/Footer";
const recommendations = [
  {
    imgSrc: "mai.webp",
    title: "Chiang Mai, Thailand",
    description:
      "Discover the magic of Chiang Mai with Aranburi, where you can explore a perfect blend of cultural richness, natural beauty, and sustainable luxury. Wander through ancient temples like Wat Phra That Doi Suthep, immerse yourself in lush landscapes at Doi Inthanon National Park, and enjoy unique experiences such as ethical elephant sanctuaries and traditional Thai cooking classes. Stay in eco-luxury resorts that prioritize sustainability, engage with local communities through cultural exchanges, and savor the vibrant arts, crafts, and cuisine. With Aranburi, every moment in Chiang Mai is designed to be both indulgent and environmentally responsible, ensuring an unforgettable and meaningful travel experience.",
  },
  {
    imgSrc: "bangkok.jpeg",
    title: "Bangkok, Thailand",
    description:
      "Experience the bustling metropolis of Bangkok with Aranburi, where you can immerse yourself in a vibrant blend of traditional culture and modernity. Visit iconic landmarks like the Grand Palace and Wat Arun, explore bustling street markets such as Chatuchak Weekend Market, and indulge in the city's dynamic culinary scene. Enjoy a boat ride along the Chao Phraya River, witness the vibrant nightlife in areas like Khao San Road, and take part in traditional Thai festivals. Stay in luxurious hotels that offer top-notch amenities and stunning city views. With Aranburi, your journey through Bangkok promises to be both exciting and memorable, blending cultural exploration with modern comforts.",
  },
  {
    imgSrc: "phuket.jpeg",
    title: "Phuket, Thailand",
    description:
      "Relax on the beautiful beaches of Phuket with Aranburi, where you can enjoy a perfect mix of relaxation and adventure. Lounge on pristine shores like Patong and Kata Beach, partake in thrilling water activities such as snorkeling and diving, and explore the island's lively nightlife. Visit the iconic Big Buddha, take a day trip to the stunning Phi Phi Islands, and enjoy the breathtaking views from Promthep Cape. Stay in luxury resorts that offer beachfront views and exquisite services. With Aranburi, every moment in Phuket is crafted to offer you a blend of tranquility and excitement, ensuring a truly unforgettable vacation experience.",
  },
];

export const DestinationPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const topElement = document.documentElement;
    if (topElement) {
      topElement.scrollIntoView({ behavior: "instant", block: "start" });
    }
  }, []);
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? recommendations.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === recommendations.length - 1 ? 0 : prevIndex + 1
    );
  };

  const { imgSrc, title, description } = recommendations[currentIndex];
  return (
    <div className="destination">
      <Intro
        topic="DESTIANTIONS"
        img="moutain2.jpg"
        desc="Luxury Trip Around Thailand"
        bottom="65%"
        left="30%"
      />
      <section className="welcomeSection">
        <h1>explore ThaiLand With Us</h1>
        <p>
          Discover the beauty, culture, and sustainability of Thailand with
          Aranburi. Our eco-luxury travel experiences offer a unique way to
          explore this enchanting country, ensuring that every moment of your
          journey is both indulgent and environmentally responsible.
        </p>
      </section>

      <section className="partSection">
        <Swiper
          freeMode={false}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            1115: {
              slidesPerView: 2,
              spaceBetween: 90,
            },
            1200: {
              slidesPerView: 2,
              spaceBetween: 60,
            },
            1600: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
          style={{
            "--swiper-pagination-color": "#0D281E",
            "--swiper-pagination-bullet-inactive-color": "#999999",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "10px",
            "--swiper-pagination-bullet-horizontal-gap": "6px",
          }}
        >
          <SwiperSlide>
            <img src="north.jpg" alt="" />
            <h1>Northern</h1>
          </SwiperSlide>
          <SwiperSlide>
            <img src="southern.jpg" alt="" />
            <h1>Southern</h1>
          </SwiperSlide>
          <SwiperSlide>
            <img src="central.jpg" alt="" />

            <h1>Central</h1>
          </SwiperSlide>
          <SwiperSlide>
            <img src="evan.jpg" alt="" />
            <h1>East</h1>
          </SwiperSlide>
        </Swiper>
      </section>

      <div className="greenSection">
        <img src="green.jpg" alt="" />
      </div>

      <section className="recommendation">
        <div className="top">
          <h1>Our Recommendation</h1>
          <div className="line"></div>
        </div>
        <div className="middle">
          <FontAwesomeIcon
            icon={faChevronLeft}
            size="2xl"
            onClick={handlePrev}
          />
          <div className="content">
            <div className="imgcontainer">
              <img src={imgSrc} alt={title} />
            </div>
            <div className="right">
              <h1>{title}</h1>
              <p>{description}</p>
              <div>
                <button>EXPLORE</button>
              </div>
            </div>
          </div>
          <FontAwesomeIcon
            icon={faChevronRight}
            size="2xl"
            onClick={handleNext}
          />
        </div>
        <div className="bottom">
          <p>
            {currentIndex + 1} out of {recommendations.length}
          </p>
        </div>
      </section>

      <Why />
      <Footer />
    </div>
  );
};
