import "./header.scss";
import { useNavigate } from "react-router-dom";

export const Header = ({ img, topic, desc, bottom, left }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <section className="headerSection">
      <div
        className="us"
        style={{
          "--img": `url(${img})`,
          "--left": left,
          "--bottom": bottom,
        }}
      >
        <div className="content">
          <h1>{topic}</h1>
          <p>{desc}</p>
        </div>
      </div>
      <div className="gold"></div>
      <div className="back">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleClick}
        >
          <rect width="48" height="48" fill="url(#pattern0_367_522)" />
          <defs>
            <pattern
              id="pattern0_367_522"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use href="#image0_367_522" transform="scale(0.0111111)" />
            </pattern>
            <image
              id="image0_367_522"
              width="90"
              height="90"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAABcklEQVR4nO3ay0rDQBSA4ejCC/gkPoFwDnTrW2U7AypWClplxn2XLoov5QWvy8i0bgpNFJyesc3/QXaFDD/paTNJVQEAAAAAAAAAAAAAAAAAsGg4PN6NTk+i04fg5C06HU9Oj/bplDuy12n02iwcTsc5z9NrrZHnoV9Kr2/zI88OeS69xh5ETle0nJVe5+ZH9jpNnyu91rU1qQ93gte7rsjBy/1tPdgrvda1RWQDRDZAZANENkBkA0Q2QGQDRDaQtjV/ccfH4Ttu1pw8Ra+jzi3i4OSGkJrlQgper5ZGrutqOzj9ILTm+sa+p6bLQ3v5JLSuNvRsdHi5JrTmCe30snVGpx03fgz1r7P5MTi5+PF5KX/vDBHbELENEdsQsQ0R2xCxDRHbELEN8QLNP4sdnJ5brqnPLzm+Nk21VXqdGx87bbu2bhUiZ2yJ9FxB7DST07iYP0CQOKoHB7nPg29pJjOXAQAAAAAAAAAAAAAAAKAq4At4ZTrAok3XQQAAAABJRU5ErkJggg=="
            />
          </defs>
        </svg>
      </div>
    </section>
  );
};
