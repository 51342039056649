// import { app } from "../../firebase";
// import "./google.scss";
// import {
//   GoogleAuthProvider,
//   getAuth,
//   signInWithPopup,
//   browserPopupRedirectResolver,
// } from "firebase/auth";

// export const Google = () => {
//   const handleGoogleClick = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const auth = getAuth(app);

//       const result = await signInWithPopup(
//         auth,
//         provider,
//         browserPopupRedirectResolver
//       );
//       console.log(result);
//       const res = await fetch("http://localhost:3001/api/auth/google", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           name: result.user.displayName,
//           email: result.user.email,
//         }),
//       });
//       const data = await res.json();
//       console.log(data.token);
//       localStorage.setItem("token", data.token);
//       window.location.href = "/";
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <div className="google" onClick={handleGoogleClick}>
//       <img src="/google.svg.webp" alt="" />
//       <p>Continue with Google</p>
//     </div>
//   );
// };
import { useState, useEffect } from "react";
import { app } from "../../firebase";
import "./google.scss";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";

export const Google = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleRedirectResult = async () => {
      const auth = getAuth(app);
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          console.log(result);
          const res = await fetch(
            "https://dthbvkpyng.us-east-1.awsapprunner.com/api/auth/google",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                name: result.user.displayName,
                email: result.user.email,
              }),
            }
          );
          const data = await res.json();
          console.log(data.token);
          localStorage.setItem("token", data.token);
          window.location.href = "/";
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    handleRedirectResult();
  }, []);

  const handleGoogleClick = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);
    try {
      await signInWithRedirect(auth, provider);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="google" onClick={handleGoogleClick}>
      {loading ? (
        <div className="loading-spinner">Loading...</div> // Add your spinner or loading animation here
      ) : (
        <>
          <img src="/google.svg.webp" alt="" />
          <p>Continue with Google</p>
        </>
      )}
    </div>
  );
};
