import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <div className="left">
          <div className="logo">
            <img src="/logo.png" alt="" />
            <h1>Aranburi</h1>
          </div>
        </div>
        <div className="right">
          <div className="content">
            <h1>CONTENT</h1>
            <p>Home</p>
            <p>About us</p>
            <p>Destination</p>
            <p>Experiences</p>
            <p>Sustainability</p>
          </div>
          <div className="book">
            <h1>BOOK A TRIP</h1>
            <p>Tailor-Made Trip</p>
            <p>Pre-Made Trip</p>
            <p>One day Trip</p>
          </div>
          <div className="contact">
            <h1>CONTACT</h1>
            <div className="box">
              <FontAwesomeIcon icon={faHouse} />
              <p>office</p>
            </div>
            <div className="box">
              <FontAwesomeIcon icon={faPhone} />
              <p>+66(0) 95 894 4145</p>
            </div>
            <div className="box">
              <FontAwesomeIcon icon={faEnvelope} />
              <p>aranburi@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="line"></div>
        <div className="last">
          <p>English</p>
          <p>Privacy</p>
          <p>Community</p>
          <p>Cookie preferences</p>
        </div>
      </div>
    </div>
  );
};
